import * as React from "react";
import { useRef, useEffect } from "react";
import Box from "@mui/material/Box";

import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { useAuth } from "../../Shared/Providers/Auth.provider";
import { endpointRequest } from "../../Shared/http/httpService";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";

import { enqueueSnackbar } from "notistack";

import "react-credit-cards/lib/styles-compiled.css";

export default function UserEditModal(props) {
  const [account, setAccount] = React.useState({});
  const [pendingAccounts, setPendingAccounts] = React.useState([]);
  const [valid, setValid] = React.useState(false);
  const { token } = useAuth();

  const updateValue = (field, value) => {
    setAccount((oldAccount) => ({
      ...oldAccount,
      [field]: value,
    }));
  };

  useEffect(() => {
    getPendingAccounts();
  }, []);

  const getPendingAccounts = async () => {
    let response = await endpointRequest(
      "GET",
      "/admin/pending-accounts",
      {},
      token
    );
    if (
      response &&
      response.data &&
      response.data.list
    ) {
      setPendingAccounts(response.data.list);
    } else {
      enqueueSnackbar("Error obteniendo cuentas pendientes", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    setAccount(props.account);
  }, [props.account]);

  useEffect(() => {
    validate();
  }, [account]);

  const validate = () => {
    if (account.name === "") {
      return setValid(false);
    }
    setValid(true);
  };

  const submit = async () => {
    if (valid) {
      await editAccount();
      props.onClose();
    }
  };

  const close = () => {
    props.onClose();
  };

  const editAccount = async () => {
    let response = await endpointRequest(
      "PUT",
      "/admin/account",
      { accountData: account },
      token
    );
    if (
      response.data &&
      response.data.result &&
      response.data.result === true
    ) {
      enqueueSnackbar("Cuenta actualizada con éxito!", {
        variant: "success",
      });
    }
  };

  return (
    <React.Fragment>
      <Dialog open={props.open} onClose={() => close()}>
        <DialogTitle>
          {account.name ? "Editar Usuario" : "Asignar Usuario a cuenta"}
        </DialogTitle>
        <DialogContent>
          <Box component="form" noValidate onSubmit={submit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <FormControl fullWidth sx={{ marginBottom: 2 }}>
                <Select
                  label="Cuenta"
                  value={account.number}
                  onChange={(e) => updateValue("number", e.target.value)}
                >
                  {pendingAccounts.map((account) => (
                    <MenuItem value={account.number}>{account.number}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                required
                fullWidth
                id="number"
                label="Número de Cuenta"
                name="number"
                className="profile-field"
                value={account.number}
                onChange={(e) => updateValue("number", e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                required
                fullWidth
                id="name"
                label="Nombre"
                name="name"
                className="profile-field"
                value={account.name}
                onChange={(e) => updateValue("name", e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                required
                fullWidth
                id="lastName"
                label="Apellido"
                name="lastName"
                className="profile-field"
                value={account.lastName}
                onChange={(e) => updateValue("lastName", e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                required
                fullWidth
                id="email"
                label="Dirección de email"
                name="email"
                className="profile-field"
                value={account.email}
                onChange={(e) => updateValue("email", e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                required
                fullWidth
                id="password"
                label="Contraseña"
                name="password"
                className="profile-field"
                value={account.password}
                onChange={(e) => updateValue("password", e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                required
                fullWidth
                id="phone"
                label="Teléfono"
                name="phone"
                className="profile-field"
                value={account.phone}
                onChange={(e) => updateValue("phone", e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant="contained" onClick={() => close()}>
            Cancelar
          </Button>
          <Button
            variant="contained"
            className="gradient-button"
            disabled={!valid}
            onClick={() => submit()}
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
