import * as React from "react";
import { useEffect } from "react";

import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

import { useAuth } from "../../Shared/Providers/Auth.provider";
import { endpointRequest } from "../../Shared/http/httpService";

export default function Chart() {
  const [funds, setFunds] = React.useState([]);
  const { token } = useAuth();

  useEffect(() => {
    fundsChart();
  }, []);

  const fundsChart = async () => {
    let response = await endpointRequest(
      "GET",
      "/stats/funds/chart",
      {},
      token
    );
    if (response && response.data) setFunds(response.data.users);
  };

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const DATA_COUNT = 12;
  const labels = [];

  for (let i = 0; i < DATA_COUNT; ++i) {
    labels.push(i.toString());
  }

  const datapoints = [
    19, 18.2, 18.3, 18.15, 18.9, 18.95, 18.85, 18.78, 18.69, 18.6, 18.5, 18.4,
  ];

  const options = {
    scales: {
      y: {
        display: false,
      },
      x: {
        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Users",
        data: datapoints,
        borderColor: "red",
        fill: false,
        cubicInterpolationMode: "monotone",
        tension: 0.4,
      },
      {
        label: "Cubic interpolation",
        data: datapoints,
        borderColor: "blue",
        fill: false,
        tension: 0.4,
      },
    ],
  };

  return (
    <React.Fragment>
      <Card
        className="card-box"
        sx={{
          marginBottom: "15px",
          padding: "35px",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Typography variant="h4" sx={{ marginBottom: 1 }}>
          Balance
        </Typography>
        <Line data={data} options={options} />
      </Card>
    </React.Fragment>
  );
}
