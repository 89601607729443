import * as React from "react";
import Container from "@mui/material/Container";
import { Typography } from "@mui/material";

import TransferList from "./TransferList.component";

export default function Transfers() {
  return (
    <Container component="main" maxWidth="xl">
      <Typography variant="h3" className="container-title">
        Transferencias
      </Typography>
      <TransferList />
    </Container>
  );
}
