import * as React from "react";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import Login from "../Login/Login.component";

export default function Home() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);

  const onClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Container component="main" sx={{ height: "90vh" }}>
        <Grid
          sx={{
            minWidth: "100%",
            minHeight: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
          alignItems="center"
          justify="center"
        >
          <Login />
        </Grid>
      </Container>
    </React.Fragment>
  );
}
