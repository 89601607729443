import * as React from "react";
import Box from "@mui/material/Box";

import Nav from "./Shared/AppBar.component";
import Home from "./Home/Home.component";

export default function Landing() {
  return (
    <Box
      sx={{
        background: "linear-gradient(#4908B5, rgba(37, 23, 104, 1))",
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <Nav />
      </Box>
      <Home />
    </Box>
  );
}
