import * as React from "react";
import { useEffect } from "react";

import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

import { useAuth } from "../../Shared/Providers/Auth.provider";
import { endpointRequest } from "../../Shared/http/httpService";

export default function UserChart() {
  const [users, setUsers] = React.useState([]);
  const [labels, setLabels] = React.useState([]);
  const [data, setData] = React.useState([]);
  const { token } = useAuth();

  useEffect(() => {
    userChart();
  }, []);

  const userChart = async () => {
    let response = await endpointRequest(
      "GET",
      "/stats/users/chart",
      {},
      token
    );
    if (response && response.data) setUsers(response.data.users);
  };

  useEffect(() => {
    let lab = users.map((a) => {
      return a._id.createdAt;
    });
    let dat = users.map((a) => {
      return a.count;
    });
    setLabels(lab);
    setData(dat);
  }, [users]);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const charts = {
    labels: labels,
    datasets: [
      {
        label: "Users",
        data: data,
        borderColor: "red",
        fill: false,
        cubicInterpolationMode: "monotone",
        tension: 0.4,
      },
      {
        label: "Cubic interpolation",
        data: data,
        borderColor: "blue",
        fill: false,
        tension: 0.4,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        display: false,
      },
      x: {
        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <React.Fragment>
      <Card
        className="card-box"
        sx={{
          marginBottom: "15px",
          padding: "35px",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Typography variant="h4" sx={{ marginBottom: 1 }}>
          Total Users
        </Typography>
        <Line data={charts} options={options} />
      </Card>
    </React.Fragment>
  );
}
