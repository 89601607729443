import * as React from "react";
import { useRef, useEffect } from "react";
import Box from "@mui/material/Box";

import TextField from "@mui/material/TextField";

import IconButton from "@mui/material/IconButton";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { useAuth } from "../../Shared/Providers/Auth.provider";
import { endpointRequest } from "../../Shared/http/httpService";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from '@mui/icons-material/Clear';

import { enqueueSnackbar } from "notistack";

export default function CardModal(props) {
  const { token } = useAuth();
  const [editCard, setEditCard] = React.useState({});
  const [account, setAccount] = React.useState({});
  const [valid, setValid] = React.useState(false);
  const [cardList, setCardList] = React.useState(false);

  useEffect(() => {
    if (props.account.number !== undefined) {
      setAccount(props.account);
    }
  }, [props.account]);

  useEffect(() => {
    setEditCard({});
    getCardList();
  }, [account]);

  const getCardList = async () => {
    let response = await endpointRequest(
      "GET",
      "/admin/cards/" + account.number,
      {},
      token
    );
    if (response.data) {
      setCardList(response.data.cardList);
    }
  };

  const cancel = () => {
    setEditCard({});
  };

  const close = () => {
    props.onClose();
  };

  const addCard = () => {
    let newCard = {
      _id: "new",
      number: 0,
      activation: 0,
      account: account.number,
    };
    setCardList((cardList) => [...cardList, newCard]);
    setEditCard(newCard);
  };

  const edit = (card) => {
    setEditCard(card);
  };

  const updateValue = (field, newValue) => {
    setEditCard((oldCard) => ({ ...oldCard, [field]: newValue }));
  };

  const confirm = async () => {
    let response = await endpointRequest("PUT", "/admin/card", editCard, token);
    if (
      response.data &&
      response.data.result &&
      response.data.result === true
    ) {
      enqueueSnackbar("Tarjeta actualizada con éxito!", {
        variant: "success",
      });
      getCardList();
    }
  };

  return (
    <React.Fragment>
      <Dialog open={props.open} onClose={() => close()} sx={{ minWidth: 850 }}>
        <DialogTitle className="flex">
          Tarjetas
          <span className="nav-spacer"></span>
          <IconButton variant="contained" onClick={() => addCard()}>
            <AddIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box>
            <TableContainer sx={{ mb: 10, backgroundColor: "#290e4b" }}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Número de Tarjeta</TableCell>
                    <TableCell align="center">Estado</TableCell>
                    <TableCell align="center">Codigo de Activacion</TableCell>
                    <TableCell align="center">Acciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cardList.length &&
                    cardList.length > 0 &&
                    cardList.map((card, index) => (
                      <TableRow
                        key={card.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">
                          <TextField
                            required
                            fullWidth
                            label="Numero de tarjeta"
                            className="profile-field"
                            value={
                              editCard._id !== card._id
                                ? card.number
                                : editCard.number
                            }
                            onChange={(e) =>
                              updateValue("number", e.target.value)
                            }
                            InputLabelProps={{ shrink: true }}
                            disabled={editCard._id !== card._id}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <TextField
                            required
                            fullWidth
                            label="Estado"
                            className="profile-field"
                            value={
                              editCard._id !== card._id
                                ? card.status
                                : editCard.status
                            }
                            onChange={(e) =>
                              updateValue("status", e.target.value)
                            }
                            InputLabelProps={{ shrink: true }}
                            disabled={editCard._id !== card._id}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <TextField
                            required
                            fullWidth
                            label="Codigo de Activacion"
                            className="profile-field"
                            value={
                              editCard._id !== card._id
                                ? card.activation
                                : editCard.activation
                            }
                            onChange={(e) =>
                              updateValue("activation", e.target.value)
                            }
                            InputLabelProps={{ shrink: true }}
                            disabled={editCard._id !== card._id}
                          />
                        </TableCell>
                        <TableCell align="center">
                          {editCard._id !== card._id ? (
                            <IconButton onClick={() => edit(card)}>
                              <EditIcon />
                            </IconButton>
                          ) : (
                            <div className="flex">
                              <IconButton onClick={() => confirm()}>
                                <CheckIcon />
                              </IconButton>
                              <IconButton onClick={() => cancel()}>
                                <ClearIcon />
                              </IconButton>
                            </div>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
