import * as React from "react";
import Box from "@mui/material/Box";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import Nav from "./Shared/AppBar.component";

import { useAuth } from "../Shared/Providers/Auth.provider";

import { Outlet } from "react-router-dom";

export default function Dashboard() {
  const [name, setName] = React.useState("");
  const [image, setImage] = React.useState("");
  const { token } = useAuth();
  const [open, setOpen] = React.useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  const onChange = (isOpen) => {
    setOpen(isOpen);
  };

  const getMargins = (status, mobile, origin) => {
    if (mobile) {
      return 0;
    }
    if (status) {
      return origin === "left" ? "5vw" : "2vw";
    }
    else {
      return origin === "left" ? "15vw" : "5vw";
    }
  }

  return (
    <div
      style={{ backgroundColor: "#1A0535", height: "100%", minHeight: "100vh" }}
    >
      <Nav name={name} image={image} onChange={onChange} />
      <React.Fragment>
        <Box
          sx={{
            marginTop: 0,
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: getMargins(open, isMobile, "left"),
            marginRight: getMargins(open, isMobile, "right"),
            transition: theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
          }}
        >
          <Outlet />
        </Box>
      </React.Fragment>
    </div>
  );
}
