import { createTheme } from "@mui/material/styles";

const Theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#9a9aff",
    },
    secondary: {
      main: "#234cff",
    },
    background: {
      paper: "#25164f",
      default: "#080110",
    },
  },
  shape: {
    borderRadius: 14,
  },
});

export default Theme;
