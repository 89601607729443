import * as React from "react";
import { useEffect } from "react";
import Container from "@mui/material/Container";

import { useAuth } from "../../Shared/Providers/Auth.provider";

import Grid from "@mui/material/Grid";
import Chart from "../Chart/Chart.component";
import UserChart from "../UserStats/UserStats.component";
import CardBox from "../Shared/Components/CardBox.component";
import { endpointRequest } from "../../Shared/http/httpService";

export default function Home() {
  const { token } = useAuth();

  const [loading, setLoading] = React.useState(false);
  const [balance, setBalance] = React.useState(0);
  const [pending, setPending] = React.useState(0);
  const [earnings, setEarnings] = React.useState(0);
  const [fundsCount, setFundsCount] = React.useState(0);
  const [exchangeRate, setExchangeRate] = React.useState(0);
  const [count, setCount] = React.useState(0);

  useEffect(() => {
    getBalance();
    // getPending();
    getTodayStats();
    getExchangeRate();
    getUsers();
  }, []);

  const getBalance = async () => {
    let response = await endpointRequest("GET", "/admin/balance", {}, token);
    if (response && response.data) setBalance(response.data.balance.saldo);
  };

  const getPending = async () => {
    let response = await endpointRequest("GET", "/admin/pending", {}, token);
    if (response && response.data) setPending(response.data.pending);
  };

  const getTodayStats = async () => {
    let response = await endpointRequest("GET", "/stats/earnings", {}, token);
    if (response && response.data) {
      setEarnings(response.data.total);
      setFundsCount(response.data.count);
    }
  };


  const getExchangeRate = async () => {
    let response = await endpointRequest(
      "POST",
      "/crypto",
      {
        currency: "USDT",
        to: "MXN",
      },
      token
    );
    if (response.data) {
      setExchangeRate(response.data);
    }
  };

  const getUsers = async () => {
    let response = await endpointRequest("GET", "/stats/users", {}, token);
    if (response && response.data) setCount(response.data.count);
  };

  return (
    <Container
      component="main"
      sx={{
        maxWidth: "1500px",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
      }}
      maxWidth={false}
    >
      <div
        style={{
          width: "100%",
          display: "inline-flex",
          justifyContent: "space-between",
          marginBottom: "25px",
        }}
      >
        <Grid
          container
          spacing={7}
          style={{
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          <Grid item xs={3}>
            <CardBox header={"$" + balance} text="Saldo Sistema" icon="savings" loading={loading} />
          </Grid>
          <Grid item xs={3}>
            <CardBox header={"$" + earnings} text="Ganancias día" icon="bank" loading={loading} />
          </Grid>
          <Grid item xs={3}>
            <CardBox header={fundsCount} text="Cantidad fondeos día" icon="bank" loading={loading} />
          </Grid>
          {/*<Grid item xs={3}>
            <CardBox header={count} text="Total Usuarios" icon="users" link="accounts" loading={loading} />
          </Grid>*/}
          <Grid item xs={3}>
            <CardBox header={"$" + exchangeRate} text="USDT" icon="bank" loading={loading} />
          </Grid>
          <Grid item xs={6}>
            <Chart />
          </Grid>
          <Grid item xs={6}>
            <UserChart />
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
