import * as React from "react";
import { useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import { Typography } from "@mui/material";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import CreditCardIcon from "@mui/icons-material/CreditCard";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import IconButton from "@mui/material/IconButton";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import EditIcon from "@mui/icons-material/Edit";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import NewReleasesIcon from "@mui/icons-material/NewReleases";

import { useAuth } from "../../Shared/Providers/Auth.provider";
import { endpointRequest } from "../../Shared/http/httpService";
import AccountEditModal from "./AccountEditModal.component";
import UserEditModal from "./UserEditModal.component";
import CardModal from "./CardModal.component";

import { enqueueSnackbar } from "notistack";
import ImportAccountsModal from "./ImportAccountsModal.component";

export default function Account() {
  const [accounts, setAccounts] = React.useState([]);
  const [selectedAccount, setSelectedAccount] = React.useState({});
  const { token } = useAuth();
  const [open, setOpen] = React.useState(false);
  const [openUserModal, setOpenUserModal] = React.useState(false);
  const [openImportModal, setOpenImportModal] = React.useState(false);
  const [openCardModal, setOpenCardModal] = React.useState(false);

  useEffect(() => {
    getAccounts();
  }, []);

  const getAccounts = async () => {
    let response = await endpointRequest("GET", "/admin", {}, token);
    setAccounts(response.data.account);
  };

  const toggleAccount = async (account, status) => {
    let isNew = account.status === "new";
    account.status = status;
    let response = await endpointRequest(
      "PUT",
      "/admin/account",
      { accountData: account, isNew: isNew },
      token
    );
    if (response.data && response.data.error === false) {
      enqueueSnackbar("Cuenta actualizada con éxito!", {
        variant: "success",
      });
    } else {
      enqueueSnackbar("Hubo problemas al actualizar la cuenta", {
        variant: "error",
      });
    }
    getAccounts();
  };

  const editAccount = (account) => {
    setSelectedAccount(account);
    setOpen(true);
  };

  const editUser = (account) => {
    setSelectedAccount(account);
    setOpenUserModal(true);
  };

  const openCard = (account) => {
    setSelectedAccount(account);
    setOpenCardModal(true);
  };

  const onClose = () => {
    setOpen(false);
    setOpenUserModal(false);
    setOpenImportModal(false);
    setOpenCardModal(false);
    setSelectedAccount({});
    getAccounts();
  };

  const statusIcon = (status) => {
    switch (status) {
      case "active":
        return <CheckIcon />;
      case "disabled":
        return <CloseIcon />;
      case "new":
        return <NewReleasesIcon />;
    }
  };

  const deleteAccount = async (id) => {
    let response = await endpointRequest(
      "POST",
      "/admin/account",
      { id: id },
      token
    );
    if (response.data && response.data.error === false) {
      enqueueSnackbar("Cuenta eliminada con éxito!", {
        variant: "success",
      });
    } else {
      enqueueSnackbar("Hubo problemas al eliminar la cuenta", {
        variant: "error",
      });
    }
    getAccounts();
  };

  return (
    <Container component="main" maxWidth="xl" sx={{ textAlign: "center" }}>
      <AccountEditModal
        open={open}
        onClose={onClose}
        account={selectedAccount}
      />

      <UserEditModal
        open={openUserModal}
        onClose={onClose}
        account={selectedAccount}
      />

      <ImportAccountsModal
        open={openImportModal}
        onClose={onClose}
      />

      <CardModal
        open={openCardModal}
        onClose={onClose}
        account={selectedAccount}
      />

      <Typography variant="h3" className="container-title">
        Cuentas
      </Typography>

      <Grid container spacing={4}>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          <Card
            sx={{
              width: 320,
              height: 182,
              borderRadius: "5%",
              display: "inline-block",
              marginBottom: 10,
            }}
            className="card-box"
          >
            <CardContent
              sx={{
                textAlign: "center",
                verticalAlign: "middle",
                cursor: "pointer",
              }}
              onClick={() => editAccount({})}
            >
              <Grid
                container
                spacing={2}
                style={{
                  marginTop: "auto",
                  marginBottom: "auto",
                }}
              >
                <Grid
                  item
                  xs={4}
                  sx={{
                    marginTop: "auto",
                    marginBottom: "auto",
                  }}
                >
                  <AccountBalanceIcon sx={{ width: "60%", height: "60%" }} />
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="h6">Crear Cuenta</Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          <Card
            sx={{
              width: 320,
              height: 182,
              borderRadius: "5%",
              display: "inline-block",
              marginBottom: 10,
            }}
            className="card-box"
          >
            <CardContent
              sx={{
                textAlign: "center",
                verticalAlign: "middle",
                cursor: "pointer",
              }}
              onClick={() => editUser({})}
            >
              <Grid
                container
                spacing={2}
                style={{
                  marginTop: "auto",
                  marginBottom: "auto",
                }}
              >
                <Grid
                  item
                  xs={4}
                  sx={{
                    marginTop: "auto",
                    marginBottom: "auto",
                  }}
                >
                  <PersonAddIcon sx={{ width: "60%", height: "60%" }} />
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="h6">Asignar Usuario</Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          <Card
            sx={{
              width: 320,
              height: 182,
              borderRadius: "5%",
              display: "inline-block",
              marginBottom: 10,
            }}
            className="card-box"
          >
            <CardContent
              sx={{
                textAlign: "center",
                verticalAlign: "middle",
                cursor: "pointer",
              }}
              onClick={() => setOpenImportModal(true)}
            >
              <Grid
                container
                spacing={2}
                style={{
                  marginTop: "auto",
                  marginBottom: "auto",
                }}
              >
                <Grid
                  item
                  xs={4}
                  sx={{
                    marginTop: "auto",
                    marginBottom: "auto",
                  }}
                >
                  <PersonAddIcon sx={{ width: "60%", height: "60%" }} />
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="h6">Importar Cuentas</Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <TableContainer
        component={Paper}
        sx={{ mb: 10, backgroundColor: "#290e4b" }}
      >
        <Table sx={{ minWidth: 650, mb: 20 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Número de Cuenta</TableCell>
              <TableCell align="center">Nombre</TableCell>
              <TableCell align="center">Estado</TableCell>
              <TableCell align="center">Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {accounts.length &&
              accounts.length > 0 &&
              accounts.map((account, index) => (
                <TableRow
                  key={account.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">{account.number}</TableCell>
                  <TableCell align="center">
                    {account.name + " " + account.lastName}
                  </TableCell>
                  <TableCell align="center">
                    {statusIcon(account.status)}
                  </TableCell>
                  <TableCell align="center">
                    <Box>
                      {account.status !== "active" ? (
                        <IconButton
                          onClick={() => toggleAccount(account, "active")}
                        >
                          <ToggleOffIcon />
                        </IconButton>
                      ) : (
                        <IconButton
                          onClick={() => toggleAccount(account, "disabled")}
                        >
                          <ToggleOnIcon />
                        </IconButton>
                      )}
                      <IconButton onClick={() => editAccount(account)}>
                        <AccountBalanceIcon />
                      </IconButton>
                      <IconButton onClick={() => openCard(account)}>
                        <CreditCardIcon />
                      </IconButton>
                      <IconButton onClick={() => editUser(account)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => deleteAccount(account._id)}>
                        <PersonRemoveIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}
