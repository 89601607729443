import * as React from "react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";

import { Link } from "react-router-dom";

import AddIcon from "@mui/icons-material/Add";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import BalanceIcon from "@mui/icons-material/Balance";
import SavingsIcon from "@mui/icons-material/Savings";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import GroupIcon from "@mui/icons-material/Group";

export default function CardBox(props) {
  const statusIcon = (status) => {
    switch (status) {
      case "add":
        return <AddIcon sx={{ width: "80%", height: "80%" }} />;
      case "bank":
        return <AccountBalanceIcon sx={{ width: "80%", height: "80%" }} />;
      case "balance":
        return <BalanceIcon sx={{ width: "80%", height: "80%" }} />;
      case "savings":
        return <SavingsIcon sx={{ width: "80%", height: "80%" }} />;
      case "transfer":
        return <CurrencyExchangeIcon sx={{ width: "80%", height: "80%" }} />;
      case "users":
        return <GroupIcon sx={{ width: "80%", height: "80%" }} />;
    }
  };

  return (
    <Card
      className="card-box"
      sx={{
        width: "100%",
        height: "182px",
        cursor: props.link !== "" ? "pointer" : "default",
      }}
    >
      <Link
        to={props.link ? props.link : ""}
        style={{
          cursor: "inherit",
        }}
      >
        <CardContent>
          {props.loading === true ? (
            <Skeleton />
          ) : (
            <Grid
              container
              spacing={2}
              style={{
                marginTop: "auto",
                marginBottom: "auto",
              }}
            >
              <Grid
                item
                xs={4}
                style={{
                  marginTop: "auto",
                  marginBottom: "auto",
                }}
              >
                {statusIcon(props.icon)}
              </Grid>
              <Grid
                item
                xs={8}
                sx={{
                  marginTop: "auto",
                  marginBottom: "auto",
                  textAlign: "center",
                }}
              >
                <Typography variant="h4">{props.header}</Typography>
                <Typography variant="h6">{props.text}</Typography>
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Link>
    </Card>
  );
}
