import * as React from "react";
import Box from "@mui/material/Box";

import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import { endpointRequest } from "../../Shared/http/httpService";

import { useAuth } from "../../Shared/Providers/Auth.provider";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import "react-credit-cards/lib/styles-compiled.css";

export default function ImportAccountsModal(props) {
  const [softToken, setSoftToken] = React.useState(0);
  const { token } = useAuth();

  const close = () => {
    props.onClose();
  };

  const importAccounts = async () => {
    let response = await endpointRequest("POST", "/admin/account/import", { softToken: softToken }, token);
  };

  return (
    <React.Fragment>
      <Dialog open={props.open} onClose={() => close()}>
        <DialogTitle>
          Importar Cuentas
        </DialogTitle>
        <DialogContent>
          <Box component="form" noValidate sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <TextField
                required
                fullWidth
                id="softToken"
                label="Token de autenticación"
                name="softToken"
                className="profile-field"
                value={softToken}
                onChange={(e) => setSoftToken(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant="contained" onClick={() => close()}>
            Cancelar
          </Button>
          <Button
            variant="contained"
            className="gradient-button"
            onClick={() => importAccounts()}
          >
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
