import { Navigate } from "react-router-dom";
import { useAuth } from "./Auth.provider";

export const ProtectedRoute = ({ children }) => {
  const { token } = useAuth();

  if (!token || token === null) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export const AdminRoute = ({ children }) => {
  const { token } = useAuth();

  if (!token || token === null) {
    return <Navigate to="/" replace />;
  }

  return children;
};
