import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";

import { styled } from "@mui/system";

import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { endpointRequest } from "../../Shared/http/httpService";
import { useAuth } from "../../Shared/Providers/Auth.provider";

const LoginInput = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    backgroundColor: "rgb(94, 65, 179)",
  },
  "& .MuiOutlinedInput-input:autofill": {
    "-webkit-box-shadow": "none",
  },
});

const PasswordInput = styled(OutlinedInput)({
  "&": {
    backgroundColor: "rgb(94, 65, 179)",
  },
  "& .MuiOutlinedInput-input:autofill": {
    "-webkit-box-shadow": "none",
  },
});

export default function Login() {
  const { login, token, logout } = useAuth();

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const data = {};
    Array.from(formData.entries()).forEach(([key, value]) => {
      data[key] = value;
    });
    let response = await endpointRequest("POST", "/login/admin", data);
    try {
      login(response.data.idToken);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          padding: "30px",
          position: "relative",
          mt: "10px",
          textAlign: "center",
          "&::before": {
            display: "block",
            position: "absolute",
            width: 0,
            height: 0,
            top: -25,
            left: "5%",
            borderLeft: "15px solid transparent",
            borderRight: "15px solid transparent",
            borderBottom: "15px solid rgba(255, 255, 255, 0.12)",
          },
        }}
      >
        <Link to="/">
          <Box component="img" src="/assets/logo.png" style={{ width: "400px" }} />
        </Link>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <LoginInput
                autoComplete="username"
                required
                fullWidth
                id="username"
                label="Nombre de Usuario"
                name="username"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl variant="standard" fullWidth>
                <PasswordInput
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  label="Contraseña"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <InputLabel
                  htmlFor="password"
                  sx={{
                    top: "-8px",
                    left: "15px",
                  }}
                >
                  Contraseña *
                </InputLabel>
              </FormControl>
            </Grid>
          </Grid>
          <Button type="submit" fullWidth variant="contained" className="gradient-button" sx={{ mt: 3, mb: 2 }}>
            Ingresar
          </Button>
        </Box>
      </Box>
    </React.Fragment>
  );
}
