import * as React from "react";
import { useRef, useEffect } from "react";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Typography } from "@mui/material";

import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import { endpointRequest } from "../../Shared/http/httpService";
import { useAuth } from "../../Shared/Providers/Auth.provider";

import { enqueueSnackbar } from "notistack";

export default function Settings() {
  const [loading, setLoading] = React.useState(false);
  const [commission, setCommission] = React.useState(0);
  const { token } = useAuth();

  useEffect(() => {
    getCommission();
  }, []);

  const getCommission = async () => {
    let response = await endpointRequest("GET", "/bank/commission", {}, token);
    if (response) setCommission(response.data.commission);
  };

  const updateCommission = async () => {
    let response = await endpointRequest("PUT", "/admin/commission", {commission: commission}, token);
    if (response) {
      enqueueSnackbar("Comision actualizada con éxito!", {
        variant: "success",
      });
    } else {
      enqueueSnackbar("Hubo un error al actualizar la comision.", {
        variant: "error",
      });
    }
  };

  return (
    <Container component="main" maxWidth="xl">
      <Typography variant="h3" className="container-title">
        Configuraciones
      </Typography>
      <Card
        sx={{
          background: "rgba(255, 255, 255, 0.05)",
          display: "flex",
          justifyContent: "center",
          width: "50%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Box>
          <CardContent>
            <FormControl fullWidth>
              <TextField
                required
                fullWidth
                id="to"
                label="Comision"
                name="to"
                autoComplete="family-name"
                className="profile-field"
                value={commission}
                onChange={(e) => setCommission(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
              <Button variant="contained" onClick={updateCommission}>
                Actualizar
              </Button>
            </FormControl>
          </CardContent>
        </Box>
      </Card>
    </Container>
  );
}
