import * as React from "react";
import { useCallback } from "react";
import "./App.css";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { Routes, Route } from "react-router-dom";

import Landing from "./Landing/Landing.component";

import Dashboard from "./Dashboard/Dashboard.component";
import Home from "./Dashboard/Home/Home.component";
import Accounts from "./Dashboard/Accounts/Accounts.component";
import Transfers from "./Dashboard/Transfers/Transfers.component";
import Funds from "./Dashboard/Funds/Funds.component";
import CardFunds from "./Dashboard/CardFunds/CardFunds.component";
import Settings from "./Dashboard/Settings/Settings.component";
import PageNotFound from "./Shared/Components/PageNotFound.component";

import { ProtectedRoute, AdminRoute } from "./Shared/Providers/Protected.route";
import { useAuth } from "./Shared/Providers/Auth.provider";

import { GlobalLoading } from "react-global-loading";
import CircularProgress from "@mui/material/CircularProgress";

import Theme from "./Shared/theme";

function App() {
  const { token } = useAuth();

  return (
    <ThemeProvider theme={Theme}>
      <CssBaseline />
      <Routes>
        <Route>
          <Route
            path="/"
            element={
              token ? (
                <Dashboard />
              ) : (
                <React.Fragment>
                  <Landing />
                </React.Fragment>
              )
            }
          >
            <Route
              path="accounts"
              element={
                <ProtectedRoute>
                  <Accounts />
                </ProtectedRoute>
              }
            />
            <Route
              path="transfers"
              element={
                <ProtectedRoute>
                  <Transfers />
                </ProtectedRoute>
              }
            />
            <Route
              path="funds"
              element={
                <ProtectedRoute>
                  <Funds />
                </ProtectedRoute>
              }
            />
            <Route
              path="card-funds"
              element={
                <ProtectedRoute>
                  <CardFunds />
                </ProtectedRoute>
              }
            />
            <Route
              path="settings"
              element={
                <ProtectedRoute>
                  <Settings />
                </ProtectedRoute>
              }
            />
            <Route
              path=""
              element={
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
      <GlobalLoading>
        <CircularProgress />
      </GlobalLoading>
    </ThemeProvider>
  );
}

export default App;
