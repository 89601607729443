import * as React from "react";
import { useRef, useEffect } from "react";
import Container from "@mui/material/Container";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { endpointRequest } from "../../Shared/http/httpService";
import { useAuth } from "../../Shared/Providers/Auth.provider";

export default function TransferList() {
  const [transactions, setTransctions] = React.useState([]);

  const { token } = useAuth();

  useEffect(() => {
    getTransactions();
  }, []);

  const getTransactions = async () => {
    let response = await endpointRequest(
      "GET",
      "/admin/transactions",
      {},
      token
    );
    if (response) setTransctions(response.data.transactions);
  };

  return (
    <Container component="main" maxWidth="xl" sx={{ textAlign: "center" }}>
      <TableContainer
        component={Paper}
        sx={{ mb: 10, backgroundColor: "#290e4b" }}
      >
        <Table sx={{ minWidth: 650, mb: 20 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Número de Cuenta</TableCell>
              <TableCell align="center">Monto</TableCell>
              <TableCell align="center">Estado</TableCell>
              <TableCell align="center">Fecha Alta</TableCell>
              <TableCell align="center">Fecha Actualización</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions &&
              transactions.length &&
              transactions.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">{row.account}</TableCell>
                  <TableCell align="center">{row.amount}</TableCell>
                  <TableCell align="center">{row.status}</TableCell>
                  <TableCell align="center">
                    {new Date(row.createdAt).toLocaleString()}
                  </TableCell>
                  <TableCell align="center">
                    {new Date(row.updatedAt).toLocaleString()}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}
