import * as React from "react";
import { useRef, useEffect } from "react";
import Container from "@mui/material/Container";
import { Typography } from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { endpointRequest } from "../../Shared/http/httpService";
import { useAuth } from "../../Shared/Providers/Auth.provider";

import Grid from "@mui/material/Grid";
import CardBox from "../Shared/Components/CardBox.component";

export default function Funds() {
  const [funds, setFunds] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [balance, setBalance] = React.useState(0);
  const { token } = useAuth();

  useEffect(() => {
    getFunds();
  }, []);

  const getFunds = async () => {
    let response = await endpointRequest("GET", "/admin/funds", {}, token);
    if (response) setFunds(response.data.funds);
  };

  return (
    <Container component="main" maxWidth="xl">
      <Typography variant="h3" className="container-title">
        Fondeos
      </Typography>

      <Grid
        container
        spacing={7}
        style={{
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        <Grid item xs={3}>
          <CardBox header={balance} text="Total Fondeos" icon="savings" loading={loading} />
        </Grid>
        <Grid item xs={3}>
          <CardBox header={balance} text="Fondeos Pendientes" icon="savings" loading={loading} />
        </Grid>
        <Grid item xs={3}>
          <CardBox header={balance} text="Fondeos Completados Hoy" icon="savings" loading={loading} />
        </Grid>
        <Grid item xs={3}>
          <CardBox header={balance} text="Fondeos Incompletos Hoy" icon="savings" loading={loading} />
        </Grid>
      </Grid>
      <TableContainer component={Paper} sx={{ mb: 10, backgroundColor: "#290e4b" }}>
        <Table sx={{ minWidth: 650, mb: 20 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Número de Cuenta</TableCell>
              <TableCell align="center">Monto</TableCell>
              <TableCell align="center">Estado</TableCell>
              <TableCell align="center">Fecha Alta</TableCell>
              <TableCell align="center">Fecha Actualización</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {funds &&
              funds.length &&
              funds.map((row) => (
                <TableRow key={row.name} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell align="center">{row.account}</TableCell>
                  <TableCell align="center">{row.amount}</TableCell>
                  <TableCell align="center">{row.status}</TableCell>
                  <TableCell align="center">{new Date(row.createdAt).toLocaleString()}</TableCell>
                  <TableCell align="center">{new Date(row.updatedAt).toLocaleString()}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}
