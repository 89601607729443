import * as React from "react";
import { useRef, useEffect } from "react";
import Box from "@mui/material/Box";

import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import IconButton from "@mui/material/IconButton";

import ReplayIcon from '@mui/icons-material/Replay';

import { useAuth } from "../../Shared/Providers/Auth.provider";
import { endpointRequest } from "../../Shared/http/httpService";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { enqueueSnackbar } from "notistack";

import "react-credit-cards/lib/styles-compiled.css";

export default function AccountEditModal(props) {
  const [edit, setEdit] = React.useState(false);
  const [account, setAccount] = React.useState({});
  const [valid, setValid] = React.useState(false);
  const { token } = useAuth();

  const updateValue = (field, value) => {
    setAccount((oldAccount) => ({
      ...oldAccount,
      [field]: value,
    }));
  };

  useEffect(() => {
    setAccount(props.account);
  }, [props.account]);

  useEffect(() => {
    validate();
  }, [account]);

  const validate = () => {
    if (account.name === "") {
      return setValid(false);
    }
    setValid(true);
  };

  const submit = async () => {
    if (valid) {
      await editAccount();
      props.onClose();
    }
  };

  const close = () => {
    props.onClose();
  };

  const updateApi = async () => {
    let response = await endpointRequest(
      "POST",
      "/admin/refresh-api",
      {
        number: account.number
      },
      token
    );
    if (
      response.data &&
      response.data.result &&
      response.data.result === true
    ) {
      enqueueSnackbar("Cuenta actualizada con éxito!", {
        variant: "success",
      });
    }
  }

  const editAccount = async () => {
    if (edit) {
      let response = await endpointRequest(
        "PUT",
        "/admin/account",
        account,
        token
      );
      if (
        response.data &&
        response.data.result &&
        response.data.result === true
      ) {
        enqueueSnackbar("Cuenta actualizada con éxito!", {
          variant: "success",
        });
      }
    } else {
      let response = await endpointRequest(
        "POST",
        "/admin/account",
        account,
        token
      );
      if (
        response.data &&
        response.data.result &&
        response.data.result === true
      ) {
        enqueueSnackbar("Cuenta actualizada con éxito!", {
          variant: "success",
        });
      }
    }

  };

  return (
    <React.Fragment>
      <Dialog open={props.open} onClose={() => close()}>
        <DialogTitle>
          {account.name ? "Editar Cuenta" : "Nueva Cuenta"}
          <IconButton
            onClick={() => updateApi()}
          >
            <ReplayIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box component="form" noValidate onSubmit={submit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <TextField
                required
                fullWidth
                id="number"
                label="Número de Cuenta"
                name="number"
                className="profile-field"
                value={account.number}
                onChange={(e) => updateValue("number", e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                required
                fullWidth
                id="apiToken"
                label="Token de Cuenta"
                name="apiToken"
                className="profile-field"
                value={account.apiToken}
                onChange={(e) => updateValue("apiToken", e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                required
                fullWidth
                id="bankToken"
                label="Token de Banco"
                name="bankToken"
                className="profile-field"
                value={account.bankToken}
                onChange={(e) => updateValue("bankToken", e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                required
                fullWidth
                id="accountId"
                label="Id de Cuenta"
                name="accountId"
                className="profile-field"
                value={account.accountId}
                onChange={(e) => updateValue("accountId", e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                required
                fullWidth
                id="clientId"
                label="Id de Cliente"
                name="clientId"
                className="profile-field"
                value={account.clientId}
                onChange={(e) => updateValue("clientId", e.target.value)}
                InputLabelProps={{ shrink: true }}
              />            </Grid>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant="contained" onClick={() => close()}>
            Cancelar
          </Button>
          <Button
            variant="contained"
            className="gradient-button"
            disabled={!valid}
            onClick={() => submit()}
          >
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
